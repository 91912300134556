import React from "react";
import Layout from "../components/layouts/main-layout";
import Container from "../components/container";
import OrderedList from "../components/ordered-list";

const AGB = () => {
  return (
    <Layout>
      <Container>
        <h1>AGB</h1>
        <h2>Allgemeines</h2>
        <OrderedList>
          <li>
            Inhaber des Tidens Surfhuus ist die Harder & Wolff GbR, Am Teich 2,
            26465 Langeoog Geschäftsführer: Sven Harder und Hendrik Wolff
            nachfolgend als „wir“ oder „uns“ bezeichnet.
            <br />
            Die Harder & Wolff GbR ist Träger der unter der Geschäftsbezeichnung
            Tidens Surfhuus erbrachten Leistungen, sowie der unter der
            Geschäftsbezeichnung Tidens Surfhuus geschlossenen Verträge.
          </li>
          <li>
            Wesentlicher Gegenstand des Tidens Surfhuus ist die Vermietung von
            Ausrüstungsgegenständen für Wassersportaktivitäten sowie die
            Durchführung von Wassersportkursen und Schulungen (insbesondere für
            Wellenreiten, Kitesurfen, Wingsurfen/Wingfoilen, Windsurfen und
            Stand-Up-Paddling). Zudem bietet das Tidens Surfhuus auch
            Lager-Leistungen (Storage) an.
            <br />
            Die nachfolgenden Geschäftsbedingungen sind Grundlage sämtlicher
            Vertragsbeziehungen zwischen dem und seinen Kunden.
            <br />
            Hierbei gelten die unter B) bis D) dargestellten Regelungen speziell
            für die jeweiligen Vertragstypen, die unter E) und F) dargestellten
            Bedingungen gelten allgemein für sämtliche abgeschlossenen Verträge.
          </li>
        </OrderedList>
        <h2 className="mt-4">Vermietung</h2>
        <h3>§ 1 Nutzung der Mietsache</h3>
        <OrderedList>
          <li>
            Der Kunde verpflichtet sich, mit der Mietsache schonend und
            pfleglich umzugehen. Sofern im Rahmen der Nutzung Schäden an den
            Mietgegenständen auftreten verpflichtet sich der Kunde, diese
            Schäden bei Rückgabe unaufgefordert und vollständig anzuzeigen.
          </li>
          <li>
            Die gemietete Ausrüstung darf nur vom Kunden selbst verwendet
            werden. Eine Weitergabe an Dritte ist ausdrücklich untersagt.
          </li>
          <li>
            Die Mietgegenstände werden von uns regelmäßig untersucht und
            sorgfältig geprüft. Dies entbindet den Kunden jedoch nicht von der
            Verpflichtung, sich vor der Nutzung nochmals über die
            Funktionstüchtigkeit der Gegenstände zu vergewissern.
          </li>
          <li>
            Tidens Surfhuus ist als Vermieter berechtigt, die Übergabe der
            Kitesurf, Surf, Kajak und Landboardausrüstung zu verweigern, sofern
            der Mieter nicht über die erforderliche Qualifikation (VDWS oder IKO
            Lizenz) verfügt.
          </li>
          <li>
            Sofern sich nach Übergabe trotz Lizenz eine mangelnde Qualifikation
            (mangelnde Beherrschung des Sportgerätes, Verletzung der Ausweich-
            und Fahrregeln, Gefährdung Anderer) des Mieters hinsichtlich der
            sicheren Führung des Kitesurfmaterials offenbart oder dieser
            entgegen den vorgegebenen Weisungen handelt, kann der Vermieter den
            sofortigen Rücktritt vom Vertrag erklären und die Mietgebühr
            einbehalten.
          </li>
        </OrderedList>
        <h3>§ 2 Zahlung</h3>
        <OrderedList>
          <li>
            Die Miete ist bei Übergabe der Mietsache in voller Höhe im Voraus zu
            entrichten.
          </li>
          <li>
            Das Risiko, die Mietsache nicht oder nur in einem eingeschränkten
            Umfang nutzen zu können liegt beim Kunden (Witterung, Wind,
            persönliche Gründe wie Erkrankung des Mieters, etc.). Wir sind in
            diesem Fall nicht zur Erstattung von Kosten verpflichtet.
          </li>
        </OrderedList>
        <h2 className="mt-4">Kurse und Schulungen</h2>
        <h3>§ 1 Anmeldung, Zahlung, persönliche Teilnahmevoraussetzungen</h3>
        <OrderedList>
          <li>
            Die Anmeldung zu einem Kurs erfolgt schriftlich durch Unterschrift
            auf dem Anmeldebogen bzw. durch Absendung des Online-Formulars auf
            der Internetseite tidens-surfhuus.de. Bei Minderjährigen ist die
            Unterschrift vom gesetzlichen Vertreter zu leisten bzw. eine
            Einverständniserklärung des gesetzlichen Vertreters beizubringen.
          </li>
          <li>
            Die Kursgebühr wird spätestens vor Kursbeginn in voller Höhe fällig.
            Ein nichterscheinen zum Kurs entbindet nicht von der
            Zahlungspflicht.
          </li>
          <li>
            Teilnahmeberechtigt ist jede Person, die das geforderte Mindestalter
            aufweist und in gesundheitlicher und konditioneller Hinsicht in der
            Lage ist, ohne Gefahr für sich und andere den Kurs durchzuführen.
            Hierzu gehört auch die Fähigkeit, im freien Wasser ohne Hilfsmittel
            schwimmen zu können.
          </li>
        </OrderedList>
        <h3>§ 2 Inhalt der Kurse und Schulungen</h3>
        <OrderedList>
          <li>
            Der Inhalt der Kurse wird durch die jeweilige Leistungsbeschreibung
            festgelegt.
          </li>
          <li>
            Die angegebenen Preise beziehen sich nur auf die Kursteilnahme
            selbst sowie - soweit nicht anders gekennzeichnet - die Überlassung
            der für die Durchführung des Kurses notwendigen Ausrüstung
            (Ausnahme: Neoprenschuhe und Neoprenhandschuhe, die käuflich bei uns
            erworben werden können). Beherbergungs- und Bewirtungsleistungen
            sind nicht im Preis inbegriffen.
          </li>
        </OrderedList>
        <h3>§ 3 Rücktritt / Stornobestimmungen</h3>
        <OrderedList>
          <li>
            Sofern der Kunde vor Durchführung des Kurses vom Vertrag
            zurücktritt, ihn kündigt oder zu dem gebuchten Kurs nicht erscheint,
            sind wir berechtigt, Ersatz für die bereits getroffenen Vorkehrungen
            und Aufwendungen zu verlangen. Dieser Ersatzanspruch wird wie folgt
            pauschaliert:
            <table className="table-fixed mx-2 lg:mx-8 w-full lg:w-[50%] my-6">
              <tbody>
                <tr className="py-3">
                  <td>bis 31 Tage vor Kursbeginn</td>
                  <td>20% der Kursgebühren</td>
                </tr>
                <tr className="py-2">
                  <td>ab 30 bis 25 Tage vor Kursbeginn</td>
                  <td>40% der Kursgebühren</td>
                </tr>
                <tr className="py-2">
                  <td>ab 24 bis 18 Tage vor Kursbeginn</td>
                  <td>50% der Kursgebühren</td>
                </tr>
                <tr className="py-2">
                  <td>ab 17 bis 11 Tage vor Kursbeginn</td>
                  <td>60% der Kursgebühren</td>
                </tr>
                <tr className="py-2">
                  <td>ab 10 bis 4 Tage vor Kursbeginn</td>
                  <td>90% der Kursgebühren</td>
                </tr>
                <tr className="py-2">
                  <td>ab 3 Tage vor Kursbeginn</td>
                  <td>100% der Kursgebühren</td>
                </tr>
              </tbody>
            </table>
            Dem Kunden ist der Nachweis gestattet, dass kein oder ein geringerer
            Schaden als die Pauschale entstanden ist. Dem Kunden ist es
            jederzeit möglich, einen Ersatzteilnehmer zu stellen, sofern dieser
            Ersatzteilnehmer die persönlichen Teilnahmevoraussetzungen erfüllt.
          </li>
          <li>
            Wir behalten uns das Recht vor, bis 5 Tage vor Kursbeginn vom
            Vertrag zurückzutreten, wenn die in der Leistungsbeschreibung
            genannte Mindestteilnehmerzahl nicht erreicht wird. Bereits bezahlte
            Gebühren werden in diesem Fall erstattet.
          </li>
          <li>
            Wird die Durchführung eines Kurses aufgrund von Ereignissen
            unmöglich, die nicht in unserem Verantwortungsbereich liegen und die
            für uns nicht beherrschbar sind (Unwetter, Windverhältnisse, Streik,
            sonst. Gründe höherer Gewalt), so werden wir von unserer
            Leistungspflicht frei. Wir sind in diesem Fall nur verpflichtet, den
            auf den Teilnehmer entfallenden Anteil der von uns ersparten
            Aufwendungen zu erstatten. Sofern bis zum Eintritt des Ereignisses
            50 % des Kursprogramms durchgeführt worden sind findet eine
            Erstattung nicht mehr statt.
          </li>
          <li>
            Erscheint ein Kunde nicht oder verspätet zum Kurs, so verfällt der
            Anspruch auf die versäumte Leistung. Ein Anspruch auf Nachholung
            oder Kostenerstattung besteht nicht.
          </li>
          <li>
            Teilnehmer, die einen Lehrgang nachhaltig stören, sich
            vertragswidrig verhalten oder sich und andere vorsätzlich gefährden,
            können von der weiteren Teilnahme ausgeschlossen werden.
          </li>
        </OrderedList>
        <h2 className="mt-4">Storage</h2>
        <OrderedList>
          <li>
            Im Rahmen von Storage-Verträgen ermöglichen wir den Kunden die
            Einlagerung von Eigentums Gegenständen, insbesondere von
            Wassersportutensilien.
          </li>
          <li>
            Die Einlagerung findet in Räumlichkeiten statt, die für alle unsere
            Storage-Kunden zugänglich sind, d.h. die eingelagerten Gegenstände
            werden nicht separat verschlossen. Für den Verlust oder das
            Abhandenkommen der eingelagerten Gegenstände übernehmen wir keine
            Haftung.
          </li>
        </OrderedList>
        <h2 className="mt-4">Haftung</h2>
        <OrderedList>
          <li>
            Die Nutzung der Mietsache bzw. der im Rahmen der Kurse überlassenen
            Ausrüstung erfolgt auf eigene Gefahr des Kunden. Jeder Kunde ist
            angehalten, die Nutzung eigenverantwortlich entsprechend seinem
            individuellen Können und körperlichen / gesundheitlichen Zustand zu
            gestalten.
          </li>
          <li>
            Wir haften gegenüber unseren Kunden in allen Fällen vertraglicher
            und außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit
            nach Maßgabe der gesetzlichen Bestimmungen auf Schadensersatz oder
            Ersatz vergeblicher Aufwendungen. Darüber hinaus wird die Haftung
            ausgeschlossen, soweit es sich bei der verletzten Pflicht nicht um
            eine solche Vertragspflicht handelt, deren Erfüllung die
            ordnungsgemäße Durchführung des Vertrages überhaupt erst ermöglicht
            und auf deren Einhaltung der Kunde regelmäßig vertrauen darf (sog.
            Kardinalspflicht). Dieser Haftungsausschluss gilt nicht für Schäden
            aus der Verletzung des Lebens, des Körpers oder der Gesundheit; hier
            richtet sich unsere Haftung nach den gesetzlichen Bestimmungen.
          </li>
          <li>
            Für Schäden, die dem Kunden aufgrund eines anfänglichen Sachmangels
            der Mietsache bzw. des überlassenen Ausrüstungsgegenstandes
            entstehen, wird die verschuldensunabhängige Garantiehaftung des §
            536a BGB ausgeschlossen. Wir haften insofern im Rahmen der obigen
            Bestimmungen nur bei Vorsatz oder grober Fahrlässigkeit.
          </li>
          <li>
            Für den Verlust, Diebstahl oder die Beschädigung von Kleidung,
            Wertsachen oder sonstigen persönlichen Gegenständen übernehmen wir
            keine Haftung. Dies gilt sowohl für den Verlust oder die
            Beschädigung der Gegenstände im Rahmen der Leistungserbringung als
            auch dann, wenn solche Gegenstände in den Kundenkisten der
            Wassersportstation zurückgelassen und dort von anderen Kunden
            beschädigt werden oder abhandenkommen.
          </li>
          <li>
            Es besteht die Möglichkeit, über uns eine VDWS Safety-Tool
            Versicherung für Kasko und Haftpflichtschäden abzuschließen, was von
            uns empfohlen wird (€ 39,00 / Jahr).
          </li>
          <li>
            Für selbstverschuldete Schäden (einschließlich Ausfall- und
            Folgeschäden) an dem und Ausrüstung Kitesurf, Surf, Kajak, Windsurf
            und Wellenreitl haftet der Teilnehmer/Mieter persönlich
          </li>
        </OrderedList>
        <h2 className="mt-4">Infektionsschutz</h2>
        <p>
          Die Harder & Wolff GbR unterweist ihre Mitarbeiter regelmäßig und
          überprüft die Abläufe an den Standorten mit besonderem Blick auch den
          Infektionsschutz. Dennoch kann der Anbieter für eventuelle
          Unachtsamkeit der Mitarbeiter oder Fehlverhalten der Kunden
          übernehmen. Das Infektionsrisiko wird gering gehalten, dennoch kann
          der Kunde keine Ansprüche im Falle einer Infektion gegen die Harder &
          Wolff GbR geltend machen.
        </p>
        <h2 className="mt-4">Salvatorische Klausel</h2>
        <p>
          Die Unwirksamkeit einer Regelung dieser AGB berührt nicht die
          Gültigkeit der übrigen Bestimmungen. Sollte sich eine Regelung als
          unwirksam oder undurchführbar erweisen, verpflichten sich die
          Vertragsparteien, die unwirksame Regelung durch eine neue, dem
          rechtlichen und wirtschaftlichen Erfolg der unwirksamen oder
          undurchführbaren Regelung möglichst nahekommende wirksame Bestimmung
          zu ersetzen.
        </p>
      </Container>
    </Layout>
  );
};

export default AGB;
